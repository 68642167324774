import React, { useState, useEffect, useContext } from 'react';
import uuid from 'uuid';
import { Container } from 'styles';
import * as S from 'styles/Home/styled';
import * as Context from 'context';
import { userAgent } from 'utils';

const Companies = ({ flag }) => {
  const { companiesContext } = Context;
  const [isSafari, setIsSafari] = useState(false);
  const companiesLogos = useContext(companiesContext);
  const currentLogos = companiesLogos;

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (userAgent.isSafari) setIsSafari(true);
    }

    return () => (unmounted = true);
  }, []);

  return (
    <S.StyledSection>
      <Container>
        <S.SectionTitle>As melhores empresas de tecnologia contratam aqui</S.SectionTitle>
        <S.CompaniesContainer>
          {currentLogos &&
            currentLogos.map(logo => (
              <S.CompaniesLogoWrapper
                key={uuid.v4()}
                title={logo.name}
                href={logo.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                {isSafari && logo.imageSafari !== undefined ? (
                  <img
                    src={logo.imageSafari}
                    alt={logo.name}
                    data-logo-name={logo.name}
                  />
                ) : (
                  <img
                    src={logo.image}
                    alt={logo.name}
                    data-logo-name={logo.name}
                  />
                )}
              </S.CompaniesLogoWrapper>
            ))}
        </S.CompaniesContainer>
      </Container>
    </S.StyledSection>
  );
};

export default Companies;
